import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { name, subtitle } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // const skills = [
  //   {
  //     name: 'exp',
  //     amount: 559421,
  //     noLevel: true,
  //     progress: 'p90',
  //   },
  //   {
  //     name: 'node.js',
  //     amount: 85,
  //     progress: 'p95',
  //   },
  //   {
  //     name: 'docker',
  //     amount: 75,
  //     progress: 'p85',
  //   },
  //   {
  //     name: 'postgres',
  //     amount: 71,
  //     progress: 'p75',
  //   },
  //   {
  //     name: 'redis',
  //     amount: 60,
  //     progress: 'p60',
  //   },
  // ];

  const networks = [
    {
      id: 1,
      type: 'github',
      url: 'https://github.com/l2ysho',
    },
    {
      id: 2,
      type: 'stack-overflow',
      url: 'https://stackoverflow.com/users/4018788/l2ysho',
    },
    {
      id: 3,
      type: 'linkedin',
      url: 'https://www.linkedin.com/in/richard-solár-2519b4b8',
    },
    {
      id: 4,
      type: 'github-square',
      url: 'https://gist.github.com/l2ysho',
    },
  ];

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <div className="hero-wrapper">
            <h1 className="hero-title">
              <span className="text-color-main">{name || 'Your Name'}</span>
              <br />
              {subtitle || "I'm the Unknown Developer."}
            </h1>
            {/* <div className="skills">
              {[].map((skill) => {
                return (
                  <div className="skill">
                    <div className="details">
                      <span>{skill.noLevel ? '' : 'lvl'} {skill.amount} {skill.name}</span>
                    </div>
                    <div className="bar">
                      <div id={skill.progress}/>
                    </div>
                  </div>
                );
              })}
            </div> */}
            <div className="social-links">
              {networks &&
                networks.map((network) => {
                  const { id, type, url } = network;
                  return (
                    <a
                      key={id}
                      href={url}
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label={type}
                    >
                      <i className={`fa fa-${type || 'refresh'} fa-black`} />
                    </a>
                  );
                })}
            </div>
          </div>
        </Fade>
        {/* <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <Container>
            <div className="root" style={{
              width: '50px',
              height: '48px',
              borderRadius: '100px',
              backgroundImage: 'linear-gradient(135deg, #02aab0 0%, #00cdac 100%)',
              position: 'relative',
              top: '77%',
              left: '74%',
            }} />
          </Container>
        </Fade> */}
      </Container>
    </section>
  );
};

export default Header;
